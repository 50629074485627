 import { graphql, useStaticQuery } from "gatsby";

 const usePosts = () => {
    const data = useStaticQuery(graphql`
    query {
        allMdx {
          nodes {
            frontmatter {
              title
              author
              slug
              image {
                sharp: childImageSharp {
                  fluid(
                    maxWidth: 100
                    maxHeight: 100
                    duotone: { shadow: "#663399", highlight: "#ddbbff" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            excerpt
            }
        }
    }
    `)

    return data.allMdx.nodes.map(post => ({
        title: post.frontmatter.title,
        author: post.frontmatter.author,
        slug: post.frontmatter.slug,
        image: post.frontmatter.image,
        exerpt: post.exerpt,
         
    }))
 };

 export default usePosts;